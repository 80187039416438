import { put, call, takeEvery } from 'redux-saga/effects';
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import * as ActionsModal from "../modal/actions";

const _adminsService = new AdminsService();

/**
 *
 * @param request
 * @returns {Generator<*, void, *>}
 */
function* listClientsSaga(request) {
    const { payload } = request
    let url = `admin/users?page=${payload?.page}`

    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.clientsListSuccess(response?.data));
    } catch (error) {
        const { message } = error.data
        console.log("new error", message);
        yield put(constants.clientsListFailed(error));
        yield call(toast.error, "Un problème est survenu veuillez contacter le service client.")
    }
}
function* ClientsAddSaga(loginPayload) {
    const { payload } = loginPayload
    const url = 'admin/users'

    try {
        const response = yield call(_adminsService.post, url, payload);
        yield put(constants.clientsAddSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success, "Le client a été ajouté avec succès")
    } catch (error) {
        const { message } = error.data;
        yield put(constants.clientsAddFailed(error));
        yield call(toast.error, message)
    }
}
function* ClientsEditSaga(loginPayload) {
    const { payload } = loginPayload
    const url = `admin/users/${payload.id}`
    const send_data = {
        username: payload.username,
        email: payload?.email,
        role_id: payload?.role_id,
        profile_url: "",
    }

    try {
        const response = yield call(_adminsService.put, url, send_data);
        yield put(constants.clientsEditSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success, "L'utilisateur a été modifié avec succès")
    } catch (error) {
        const { message } = error.data;
        yield put(constants.clientsEditFailed(error));
        yield call(toast.error, message)
    }
}

function* listClientsPaysSaga(loginPayload) {
    const { payload } = loginPayload

    let url = `admin/users`

    if (payload?.pays_id !== '' && payload?.pays_id !== null) {
        url += `?pays_id=${payload?.pays_id}`
    }

    if (payload?.page !== '' && payload?.page !== undefined && payload?.page !== null) {
        // url += `&page=${payload?.page}`
        url += (payload?.pays_id !== '' && payload?.pays_id !== null) ? `&page=${payload?.page}` : `?page=${payload?.page}`

    }

    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.clientsPaysListSuccess(response?.data));
    } catch (error) {
        const { message } = error.data;
        yield put(constants.clientsPaysListFailed(error));
        yield call(toast.error, message)
    }

}

export default function* clientsSaga() {
    yield takeEvery(Actions.CLIENTS_LIST_REQUESTING, listClientsSaga);
    yield takeEvery(Actions.CLIENTS_ADD_REQUESTING, ClientsAddSaga);
    yield takeEvery(Actions.CLIENTS_EDIT_REQUESTING, ClientsEditSaga);
    yield takeEvery(Actions.CLIENTS_PAYS_LIST_REQUESTING, listClientsPaysSaga);
}
