import { put, call, takeEvery } from 'redux-saga/effects';
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import * as ActionsModal from "../modal/actions";
const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listCategoriesSaga(loginPayload) {
    //const {payload}=loginPayload
    const url = 'admin/articles/categorie'
    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.categoriesListSuccess(response?.data?.categories));
    } catch (error) {
        const { message } = error.data;
        yield put(constants.categoriesListFailed(error));
        yield call(toast.error, message)
    }
}

function* addCategoriesSaga(loginPayload) {
    const { payload } = loginPayload
    const url = 'admin/articles/categorie'
    try {
        const response = yield call(_adminsService.post, url, payload);
        yield put(constants.categoriesSuccess(response?.data?.categories));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success, "La catégorie a été ajoutée avec succès.")
    } catch (error) {
        const { message } = error.data;
        yield put(constants.categoriesFailed(error));
        yield call(toast.error, message)
    }
}
export default function* categoriesSaga() {
    yield takeEvery(Actions.CATEGORIES_LIST_REQUESTING, listCategoriesSaga);
    yield takeEvery(Actions.CATEGORIES_REQUESTING, addCategoriesSaga);
}
