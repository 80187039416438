// export const INDIVIDUEL = [
//     {
//       name: "nom",
//       question: "Quel est le nom du client ?",
//       description: "",
//       type: "simple", //"mutiple",
//       options: [
//         // {value: "", price: ""}
//       ],
//     },
//     {
//       name: "prenom",
//       question: "Quel est le prénom(s) du client ?",
//       description: "",
//       type: "simple", //"mutiple",
//       options: [
//         // {value: "", price: ""}
//       ],
//     },
//     {
//       name: "numero-telephone",
//       question: "Veuillez saisir le numéro de téléphone du client",
//       description: "",
//       type: "simple",
//       options: [
//         // {value: "Veuillez saisir votre numéro de téléphone ", price: ""},
//       ],
//     },
//     {
//       name: "email",
//       question: "Quel est votre Adresse mail",
//       description: "",
//       type: "simple",
//       options: [
//         // {value: "Veuillez saisir votre adresse mail", price: ""},
//       ],
//     },
//     {
//       name: "denomination",
//       question: "Quel est le nom de l’entreprise ?",
//       description: "",
//       type: "simple", //"mutiple",
//       options: [
//         // {value: "", price: ""}
//       ],
//     },
//     {
//       name: "secteur-activite",
//       question: "Quel est votre secteur d’activités ?",
//       description: "",
//       type: "mutiple", //"mutiple",
//       options: [
//         { value: "Commerce general", price: "" },
//         { value: "Activités Immobilières", price: "" },
//         { value: "Activités Informatiques", price: "" },
//         { value: "restauration", price: "" },
//         { value: "Agence de voyage", price: "" },
//         { value: "Transport VTC", price: "" },
//         { value: "Agence de transfert d’argent", price: "" },
//         { value: "Cabinet de conseils", price: "" },
//         { value: "Autres", price: "" },
//       ],
//     },
//     {
//       name: "entreprise-anterieur",
//       question: "Avez-vous déjà créé une entreprise ?",
//       description: "",
//       type: "mutiple", //"mutiple",
//       options: [
//         { value: "Oui", price: "" },
//         { value: "Non", price: "" },
//       ],
//     },
//     {
//       name: "date-reception-certificat",
//       question:
//         "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
//       description: "",
//       type: "mutiple", //"mutiple",
//       options: [
//         { value: "1 semaine", price: "" },
//         { value: "Je ne sais pas encore", price: "" },
//       ],
//     },
//     {
//       name: "localisation-ville",
//       question: "Dans quelle commune se situe votre société ?",
//       description:
//         "Il faudra impérativement une adresse physique et postale pour votre organisation.",
//       type: "mutiple", //"mutiple",
//       options: [
//         { value: "Abidjan", price: 99_000 },
//         { value: "Hors d'Abidjan", price: 120_000 },
//       ],
//     },
//     {
//       name: "localisation-siege",
//       question: "Où se situera votre siège ?",
//       description:
//         "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
//       type: "mutiple", //"mutiple",
//       options: [
//         { value: "A mon domicile", price: "" },
//         // { value: "Je domicilie chez Legafrik", price: "" },
//         { value: "Je loue un local commercial", price: "" },
//         { value: "Je n'ai pas de siège", price: "" },
//       ],
//     },
//     {
//       name: "capital-social",
//       question: "Quel est le montant de votre capital social ?",
//       description:
//         "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
//       type: "mutiple", //"mutiple",
//       options: [
//         { value: "100 000 FCFA", price: "" },
//         { value: "1 000 000 FCFA", price: "" },
//         { value: "10 000 000 FCFA", price: "" },
//         { value: "Autres", price: "" },
//       ],
//     },
//     {
//       name: "banque",
//       question: "Dans quelle Banque, voulez-vous déposer le capital ?",
//       description: "",
//       type: "simple", //"mutiple",
//       options: [
//         // {value: "", price: ""}
//       ],
//     },
//     {
//       name: "gestion-domiciliation",
//       question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
//       description: "Choisissez une formule",
//       type: "mutiple", //"mutiple",
//       options : [
//         {value: "6 mois à 180 000 FCFA(30 000 F/MOIS)", price: 180_000},
//         {value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000},
//       ]

//     }
//   ];

export const INDIVIDUEL = [
  {
    name: "nom",
    question: "Nom du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "prenom",
    question: "Prénom(s) du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "numero-telephone",
    question: "Saisissez le numéro de téléphone du client (WhatsApp)",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "email",
    question: "Saisissez l'e-mail du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "denomination",
    question: "Entrez le nom de l'entreprise",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "secteur-activite",
    question: "Cochez le secteur d'activités",
    description: "",
    type: "mutiple",
    options: [
      { value: "Commerce général", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "Restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseils", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Le client a-t-il déjà crée une autre entreprise ?",
    description: "",
    type: "mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-certificat",
    question: "À quelle date le client souhaite-t-il recevoir son certificat d'immatriculation (IDU)?",
    description: "",
    type: "mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-ville",
    question: "La commune/ville où se situe l'entreprise du client",
    description: "Une adresse physique et postale est obligatoire pour l'entreprise.",
    type: "mutiple",
    options: [
      { value: "Abidjan", price: 99_000 },
      { value: "Hors d'Abidjan", price: 120_000 },
    ],
  },
  {
    name: "localisation-siege",
    question: "Le siège de l'entreprise du client",
    description: "Une adresse physique et postale est obligatoire pour l'entreprise.",
    type: "mutiple",
    options: [
      { value: "À mon domicile", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Le montant du capital social de l'entreprise ",
    description: "Il n'y a pas de capital minimum requis pour une SARL ou SAS en Côte d'Ivoire.",
    type: "mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "10 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "banque",
    question: "Indiquez la banque qui recevra le capital de l'entreprise   ",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "gestion-domiciliation",
    question: "Souhaitez-vous domicilier votre entreprise chez Legafrik ?",
    description: "Choisissez une formule.",
    type: "mutiple",
    options: [
      { value: "6 mois à 180 000 FCFA (30 000 F/mois)", price: 180_000 },
      { value: "12 mois à 300 000 FCFA (25 000 F/mois)", price: 300_000 },
    ],
  },
];

