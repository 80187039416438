import React from "react";
import { stringifyBool } from "./String";
import { toast } from "react-toastify";

export function variableExistsInJson(jsonStr, variableName) {
  try {
    const data = JSON.parse(jsonStr);
    return variableName in data;
  } catch (error) {
    return false;
  }
}
export const getStatusDemands = (demande) => {
  let etatText = "";
  let montant_paye = demande.montant_paye ?? 0;
  let montant_total = demande.montant_total ?? 0;
  let remaining_amount = montant_total - montant_paye;

  //   console.log("remaining_amount", remaining_amount);
  if (montant_paye === 0) {
    etatText = (
      <span className="px-3 py-1 rounded-full text-white bg-theme-6">
        À&nbsp;payer
      </span>
    );
  } else {
    if (remaining_amount <= 0) {
      etatText = (
        <span
          className="px-3 py-1 rounded-full
                        text-white bg-theme-9"
        >
          Payé
        </span>
      );
    } else {
      etatText = (
        <span
          className="px-3 py-1 rounded-full
                        text-white bg-theme-11"
        >
          À&nbsp;solder
        </span>
      );
    }
  }
  return etatText;
};

export const getStatus = (demande) => {
  let montant_paye = demande.montant_paye ?? 0;
  let montant_total = demande.montant_total ?? 0;
  let remaining_amount = montant_total - montant_paye;
  if (montant_paye === 0) {
    return true;
  } else {
    return remaining_amount > 0;
  }
};

export const getMontantRestantDemands = (montant_paye, montant_total) => {
  if (montant_paye == null) {
    return montant_total;
  } else {
    return montant_total - montant_paye;
  }
};

export function truncateText(text, maxLength) {
  if (text !== undefined && text !== null) {
    if (typeof text !== "string") {
      if (text.toString().length <= maxLength) {
        return text.toString();
      } else {
        return text.toString().substring(0, maxLength) + "...";
      }
    } else {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.substring(0, maxLength) + "...";
      }
    }
  } else {
    return text;
  }
}


export function removeFieldsenderData(obj, keysToRemove) {
  // Create a new object to store the filtered properties
  const filteredObject = {};

  // Iterate through the object's properties
  Object.keys(obj).map((key) => {
    if (!keysToRemove.includes(key)) {
      // Add the property to the filtered object
      filteredObject[key] = obj[key];
    }
  })


  // Return the filtered object
  return filteredObject;
}
export const renderData = (useDemands, items, isDemand, champs) => {
  if (useDemands?.demands_detail !== null && items !== null) {
    let removeField = [
      "type-creance",
      "Montant-dette",
      "duree-dette",
      "entreprise-debitrice",
      "transport",
      "cabinet-transit",
      "cabinet-formation",
      "solution-pay",
      "modification-du-statut",
      "valeur-de-la-modification",
      "vendeur-part",
      "nouvelle-capital",
      "nouveau-president-gerant",
      "nouveau-nom-entreprise",
      "nouveau-siege",
      "nouvelle-activite",
      "date-creation",
      "ville-immatriculation",
      "nouvelle-forme-juridique"
    ]
    let itemData = removeFieldsenderData(items, removeField);
    // console.log("items: ",items)
    return Object.keys(itemData).map((item_field, index) => {

      return (
        <div className="flex-item" key={item_field.toString()}>
          <span className="flex-item-text">{champs[item_field]}:</span>
          {
            <span className="flex-item-description">
              {itemData[item_field] !== null
                ? item_field === "denomination"
                  ? itemData[item_field]?.toString()
                  : stringifyBool(itemData[item_field])
                : isDemand
                  ? item_field === "entreprise-anterieur" ||
                    item_field === "modification-du-statut" ||
                    item_field === "valeur-de-la-modification" ||
                    item_field === "vendeur-part"
                    ? ""
                    : "Je ne suis pas intéressé"
                  : ""}
            </span>
          }
        </div>
      );
    });
  }
};
export const renderRecapCreateView = (data, items, isDemand, champs) => {
  if (data !== null && items !== null) {
    let itemData = items;
    return Object.keys(itemData).map((item_field, index) => {
      return (
        <div className="flex-item" key={item_field.toString()}>
          <span className="flex-item-text">{champs[item_field]}:</span>
          {
            <span className="flex-item-description">
              {itemData[item_field] !== null
                ? item_field === "denomination"
                  ? itemData[item_field]?.toString()
                  : stringifyBool(itemData[item_field])
                : isDemand
                  ? item_field === "entreprise-anterieur" ||
                    item_field === "modification-du-statut" ||
                    item_field === "valeur-de-la-modification" ||
                    item_field === "vendeur-part"
                    ? ""
                    : "Je ne suis pas intéressé"
                  : ""}
            </span>
          }
        </div>
      );
    });
  }
};
export const handleGenerateDocument = async (
  id,
  apiUrl,
  setIsGenerateLoading
) => {
  setIsGenerateLoading(true);
  try {
    await apiUrl
      ._post(`admin/demandes/${id}/documents/regenerate`)
      .then((result) => {
        setIsGenerateLoading(false);
        toast.success("Les documents ont été générés avec succès.");
      })
      .catch((error) => {
        setIsGenerateLoading(false);
        toast.error(
          "Une erreur s'est produite lors de la génération de documents."
        );
      });
  } catch (e) {
    toast.error(
      "Une erreur s'est produite, veuillez contacter le service client."
    );
  }
};
export function stringifyObjectValues(obj) {
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === "object" && value !== null) {
      return stringifyObjectValues(value);
    }
    return value;
  });
}
export function transformArrayNumberValueToStrings(arr) {
  return arr.map((obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = typeof value === "number" ? value.toString() : value;
      return acc;
    }, {})
  );
}
export function transformSimpleNumberValueToStrings(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = typeof value === "number" ? value.toString() : value;
    return acc;
  }, {});
}

export function stringifyValues(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    // acc[key] = ((typeof obj[key] === 'string') || (typeof obj[key] === 'number'))  ? obj[key] : JSON.stringify(obj[key]);
    if (typeof obj[key] === "number") {
      acc[key] = JSON.stringify(obj[key].toString());
    } else if (typeof obj[key] === "string") {
      acc[key] =
        obj[key] === "[]" ? JSON.stringify([]) : JSON.stringify(obj[key]);
    } else {
      if (Array.isArray(obj[key])) {
        acc[key] = JSON.stringify(transformArrayNumberValueToStrings(obj[key]));
      } else {
        acc[key] = JSON.stringify(
          transformSimpleNumberValueToStrings(obj[key])
        );
      }
    }
    return acc;
  }, {});
}

export function correspondindFields(type, country) {
  if (country === "MR") {
    if (type === "SARL" || type === "SARLU") {
      return [
        "sigle",
        "adresse",
        "associeTypes",
        "associes",
        "activites",
        "gerants",
        "mandat",
      ];
    } else if (type === "SASU" || type === "SAS") {
      return [
        "sigle",
        "adresse",
        "associeTypes",
        "associes",
        "presidents",
        "activites",
        "directeurs",
      ];
    }
  } else if (
    country === "CM" ||
    country === "TG" ||
    country === "GN" ||
    country === "RDC" ||
    country === "SN"
  ) {
    if (type === "SARL" || type === "SUARL" || type === "SARLU") {
      return [
        "sigle",
        "adresse",
        "associeTypes",
        "associes",
        "activites",
        "gerants",
      ];
    } else if (type === "SASU" || type === "SAS") {
      return [
        "sigle",
        "adresse",
        "associeTypes",
        "associes",
        "presidents",
        "activites",
        "directeurs",
      ];
    }
  } else {
    if (type === "SARL" || type === "SARLU") {
      return [
        "sigle",
        "adresse",
        "associeTypes",
        "associes",
        "activites",
        "gerants",
      ];
    } else if (type === "SASU" || type === "SAS" || type === "SCOOP") {
      return [
        "sigle",
        "adresse",
        "associeTypes",
        "associes",
        "presidents",
        "activites",
        "directeurs",
      ];
    } else if (type === "Association") {
      return [
        "sigle",
        "activites",
        "adresse",
        "president",
        "secretaire",
        "tresorier",
        "bureau",
        "commissaire",
        "commissaire-adjoint",
        "dure",
        "montant",
      ];
    } else if (type === "Fondation") {
      return [
        "sigle",
        "activites",
        "adresse",
        "president",
        "secretaire",
        "tresorier",
        "bureau",
        "commissaire",
        "dure",
        "montant",
      ];
    } else if (type === "commerciale" || type === "civile") {
      return ["sigle", "adresse", "associes", "activites", "presidents"];
    } else if (type === "Recouvrement") {
      return ["sigle", "adresse", "nombre-facture", "commentaire"];
    } else if (type === "statutaire") {
      return ["adresse1", "associes", "gerants", "info-acheteur"];
    }
  }
}

export function removeFields(obj, fields) {
  for (let key in obj) {
    if (!fields.includes(key)) {
      delete obj[key];
    }
  }
  return obj;
}

export const getTotalAmountByTypeEntreprise = (type, code_pays, type_ville) => {
  if (code_pays === "CI") {

    if (type.includes("SARL") || type.includes("sarl")) {
      if (type_ville === 'Abidjan') {
        return 50;
      } else {
        return 50;
      }
    } else if (type.includes("SARLU") || type.includes("sarlu")) {
      if (type_ville === 'Abidjan') {
        return 139000;
      } else {
        return 220000;
      }
    } else if (type.includes("SAS") || type.includes("sas")) {
      if (type_ville === 'Abidjan') {
        return 330000;
      } else {
        return 399000;
      }
    } else if (type.includes("SCOOP") || type.includes("scoop")) {
      if (type_ville === 'Abidjan') {
        return 50;
      } else {
        return 50;
      }
    } else if (type.includes("SASU") || type.includes("sasu")) {
      if (type_ville === 'Abidjan') {
        return 330000;
      } else {
        return 399000;
      }
    } else if (type.includes("SCI-CIVILE") || type.includes("sci-civile")) {
      return 250000;
    } else if (type.includes("SCI-COMMERCIALE") || type.includes("sci-commerciale")) {
      return 330000;
    } else if (type.includes("ONG") || type.includes("ong")) {
      if (type_ville === 'Abidjan') {
        return 159000;
      } else {
        return 199000;
      }
    } else if (type.includes("Association") || type.includes("association")) {
      if (type_ville === 'Abidjan') {
        return 159000;
      } else {
        return 199000;
      }
    } else if (type.includes("Fondation") || type.includes("fondation")) {
      if (type_ville === 'Abidjan') {
        return 159000;
      } else {
        return 199000;
      }
    }
  }
}

export const getOffreEntreprise = (type, code_pays, type_ville) => {
  if (code_pays === "CI") {
    if (type.includes("SARL") || type.includes("sarl")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (50 FCFA)";
      } else {
        return "Frais de constitution (50 FCFA)";
      }

    } else if (type.includes("SARLU") || type.includes("sarlu")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (139000 FCFA)";
      } else {
        return "Frais de constitution (220000 FCFA)";
      }

    } else if (type.includes("SAS") || type.includes("sas")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (330000 FCFA)";
      } else {
        return "Frais de constitution (399000 FCFA)";
      }

    } else if (type.includes("SCOOP") || type.includes("scoop")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (50 FCFA)";
      } else {
        return "Frais de constitution (50 FCFA)";
      }

    } else if (type.includes("SASU") || type.includes("sasu")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (330000 FCFA)";
      } else {
        return "Frais de constitution (399000 FCFA)";
      }

    } else if (type.includes("SCI-CIVILE") || type.includes("sci-civile")) {

      return "Frais de constitution (250000 FCFA)";

    } else if (type.includes("SCI-COMMERCIALE") || type.includes("sci-commerciale")) {

      return "Frais de constitution (330000 FCFA)";

    } else if (type.includes("ONG") || type.includes("ong")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (159000 FCFA)";
      } else {
        return "Frais de constitution (199000 FCFA)";
      }

    } else if (type.includes("Association") || type.includes("association")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (159000 FCFA)";
      } else {
        return "Frais de constitution (199000 FCFA)";
      }

    } else if (type.includes("Fondation") || type.includes("fondation")) {

      if (type_ville === 'Abidjan') {
        return "Frais de constitution (159000 FCFA)";
      } else {
        return "Frais de constitution (199000 FCFA)";
      }

    }
  }
}

export function getAmount(text) {

  const match = text?.match(/mois à ([\d\s]+) FCFA/);
  if (match) {
    return parseInt(match[1].replace(/\s/g, ''), 10);
  } else {
    return 0;
  }
  //return 1500;
}

export function getAmountOnly(text) {

  const match = text?.match(/([\d\s]+) FCFA/);
  if (match) {
    return parseInt(match[1].replace(/\s/g, ''), 10);
  } else {
    return 0
  }
  //return 1500;
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

