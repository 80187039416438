import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Icons from "react-feather";
import { toast } from "react-toastify";
import ApiService from "../api/services/api.service";
import ModalPaiment from "./ModalPaiment";
import { getLocalState } from "../utils/localStorage";
import { Components } from ".";
import { QuestionnaireCiSteps } from "./forms/questionnaires/ci/QuestionnaireCiSteps";

const apiUrl = new ApiService();

export function Payment(props) {
  console.log("props payment", props)
  const { id } = useParams();
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const moyen_paiements = Array.isArray(props.moyen_paiements) ? props.moyen_paiements : [];
  const amount = props.amount ?? "0";
  const currency = props.currency ?? "FCFA";
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [itemDemande, setItemDemande] = useState({});
  const [isPayment, setIsPayment] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const currentUser = getLocalState("_currentUser");

  const fetchDemandeDetails = useCallback(async () => {
    try {
      const result = await apiUrl._get(`admin/demandes/${id}`);
      setItemDemande(result.data.demande);
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la récupération des détails de la demande.");
    }
  }, [id]);

  useEffect(() => {
    fetchDemandeDetails();
  }, [fetchDemandeDetails]);

  const handlePaymentSubmit = async () => {
    if (!selectedPaymentId) {
      toast.error("Veuillez sélectionner un mode de paiement avant de commander.");
      return;
    }

    setIsDisabled(true);
    setIsPayment(true);

    try {
      const send = {
        champs_questionnaire: JSON.stringify(props.questionnaireObject),
      };

      await apiUrl._put(`user/demandes/${id}/champs-questionnaire`, send);
      await apiUrl._post(`toosign/demande/${id}`);

      toast.success("La demande a bien été complétée");
      handleModalClose();
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la soumission du paiement.");
    } finally {
      setIsDisabled(false);
      setIsPayment(false);
    }
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsPayment(false);
  };

  return (
    <>
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">Choix de Paiement</div>
      </div>
      <div className="intro-y box flex flex-row justify-around mt-5 space-x-4">
        {moyen_paiements
          .filter((moyen_paiement) => moyen_paiement.libelle !== "Payer plus tard" && moyen_paiement.libelle !== "Carte de crédit")
          .map((moyen_paiement) => {
            const isSelected = selectedPaymentId === moyen_paiement.id;

            const buttonClass = isSelected
              ? "border-2 border-theme-1 bg-theme-2 text-green rounded-lg p-5 cursor-default"
              : "border border-gray-300 bg-white text-gray-700 rounded-lg p-5 cursor-default";

            return (
              <div
                key={moyen_paiement.id}
                className={buttonClass}
              >
                <Icons.CreditCard className="w-10 h-10 text-theme-1 mx-auto" />
                <div className="text-xl font-medium text-center mt-3">
                  {moyen_paiement.libelle}
                </div>
              </div>
            );
          })}
      </div>
      <div className="intro-y flex justify-center mt-10">
        <div className="text-3xl font-semibold">
          {amount}
          <span className="text-base text-gray-500 ml-2">{currency}</span>
        </div>
      </div>
      <div className="intro-y flex justify-center space-x-4 mt-10">

        <button
          className="button button--lg w-32 border border-gray-400 text-gray-600"
          onClick={props.handleBackClick}
        >
          Revenir
        </button>
        <button
          disabled={isDisabled}
          type="button"
          className={`button button--lg text-white bg-theme-1 rounded-full ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={() => {
            if (!selectedPaymentId) setIsEditModalOpen(true);
            else handlePaymentSubmit();
          }}
        >
          {isPayment ? "Commande en cours..." : "Commander"}
        </button>

      </div>
      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <Components.Modal
            handleModalClose={() => setIsEditModalOpen(false)}
            handleModalValidate={() => setIsEditModalOpen(false)}
            title={"Paiement"}
            className="w-3/4 max-w-md mx-auto"
          >
            <ModalPaiment
              dataPaiement={itemDemande}
              handleStateReturnChange={handleModalClose}
              paymentAmount={amount}
              isDisabled={isDisabled}
              handlePaymentSubmit={handlePaymentSubmit}
            />
          </Components.Modal>
        </div>
      )}


    </>
  );
}
