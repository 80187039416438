export const SCOOP = [
    {
        name: "denomination",
        question: "A quel nom avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options: [
            // {value: "", price: ""}
        ],
    },
    {
        name: "nombre-associes",
        question: "Combien d’associés êtes-vous ?",
        description: "",
        type: "simple", //"mutiple",
        options: [
            // {value: "", price: ""}
        ],
    },
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options: [
            // {value: "", price: ""}
        ],
    },
    {
        name: "localisation-ville",
        question: "Dans quelle commune se situe votre organisation ?",
        description:
            "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options: [
            { value: "Abidjan", price: "" },
            { value: "Hors d'Abidjan", price: "" },
        ],
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description:
            "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options: [
            { value: "A mon domicile", price: "" },
            // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
            { value: "Je loue un local commercial", price: "" },
            { value: "Je n'ai pas de siège", price: "" },
        ],
    },

    {
        name: "gestion-domiciliation",
        question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
        description: "Choisissez une formule",
        type: "mutiple", //"mutiple",
        options: [
            { value: "6 mois à 180 000 FCFA (30 000 F/MOIS) ", price: 180_000 },
            { value: "12 mois à 300 000 FCFA (25 000 F/MOIS) ", price: 300_000 },
            { value: "Je ne suis pas intéressé", price: "" },
        ],
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description:
            "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
        type: "mutiple", //"mutiple",
        options: [
            { value: "100 000 FCFA", price: "" },
            { value: "1 000 000 FCFA", price: "" },
            { value: "100 000 000 FCFA", price: "" },
            { value: "Autres", price: "" },
        ],
    },
];
