import { put, call, takeEvery } from 'redux-saga/effects';
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listEtatEtapeTraitementsSaga(loginPayload) {
    const url = 'admin/etat-etape-traitements'
    try {
        const response = yield call(_adminsService.geList, url);
        yield put(constants.etatEtapeTraitementsListSuccess(response?.data?.etat_etape_traitements));
    } catch (error) {
        const { message } = error.data;
        yield put(constants.etatEtapeTraitementsListFailed(error));
        yield call(toast.error, message)
    }
}

export default function* etatEtapeTraitementsSaga() {
    yield takeEvery(Actions.ETAT_ETAPE_TRAITEMENT_LIST_REQUESTING, listEtatEtapeTraitementsSaga);
}
