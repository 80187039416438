import { SARL } from "./sarl";
import { SARLU } from "./sarlu";
import { SAS } from "./sas";
import { SASU } from "./sasu";
import { ONG } from "./ong";
import { DOMICILIATION } from "./domiciliation";
import { DEPOT_MARQUE } from "./depot_marque";

export const TYPES_DEMANDE_TG = {
    SARL,
    SARLU,
    SAS,
    SASU,
    ONG,
    DOMICILIATION,
    DEPOT_MARQUE,
}