import * as Icons from "react-feather";
import logoImgPoste from "../assets/images/logo-poste-ci.png";

export function Modal(props) {
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      id="header-footer-modal-preview"
      style={{ marginTop: 0, marginLeft: 0, zIndex: 90 }}
    >
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-lg mx-4 sm:mx-0">
        <button
          className="absolute  right-0 top-0 mt-3 mr-3"
          onClick={props.handleModalClose}
        >
          <Icons.X className="w-8 h-8 text-gray-500" />
        </button>
        <div
          className="grid grid-cols-3 items-center px-5 py-5 sm:py-3 border-b border-gray-200 
                dark:border-dark-5"
        >
          <div className="col-span-1">
            <img
              alt="Midone Tailwind HTML Admin Template"
              className="w-12 "
              style={{ borderRadius: 3 }}
              src={logoImgPoste}
            />
          </div>
          <h2 className="font-medium text-xl font-extrabold  text-center">{props.title ?? ""}</h2>
        </div>
        <div className="p-5 sm:w-full">{props.children}</div>
        {props.isControlVisible ? (
          <div className="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5">
            <button
              type="button"
              className="button w-20 border text-gray-700 dark:border-dark-5 
                        dark:text-gray-300 mr-1"
              onClick={props.handleModalClose}
              disabled={props.isDisabled ?? false}
            >
              Fermer
            </button>
            <button
              type="button"
              className="button bg-theme-1 text-white"
              onClick={props.handleModalValidate}
              disabled={props.isDisabled ?? false}
            >
              {props.isDisabled ? "Chargement..." : "Valider"}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
