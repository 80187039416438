import { useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../../index";
import { Hooks } from "../../../hooks";
import { Services } from "../../../services";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApiService from "../../../api/services/api.service";
import ModalPaiment from "../../ModalPaiment";
import { getLocalState } from "../../../utils/localStorage";
import { getStatus } from "../../../utils/utility";
const apiUrl = new ApiService();
export function PaymentsStep(props) {
  // console.log("PaymentsStep", props);
  const { id } = useParams();
  const useDemande = Hooks.useDemande();
  const usePaiement = Hooks.usePaiement();
  const abortController = useMemo(() => new AbortController(), []);
  const [pays, setPays] = useState("");
  const [country, setCountry] = useState();
  const [type_demande_id, setType_demande_id] = useState({});
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [itemDemande, setItemDemande] = useState({});
  const [statusPaiements, setStatusPaiements] = useState("");
  // let statusPaiements= null
  const [statePaiment, setStatPaiment] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [isPayment, setIsPayment] = useState(false);
  const currentUser = getLocalState("_currentUser");

  const navigate = useNavigate();

  // console.log("usePaiement", usePaiement);

  const [moyen_paiements, setMoyens_payements] = useState([]);
  const initialize = useCallback(async () => {
    window.localStorage.removeItem("cst");
    // const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));

    try {
      await apiUrl._get(`admin/demandes/${id}`).then((result) => {
        // console.log("result", result);
        setItemDemande(result.data.demande);
      });

      const datasPays = await Services.PaysService.getAlls(
        abortController.signal
      );

      let country = datasPays.pays.find(
        (country_) => country_.code === props.pays.code
      );

      const typeDemandes = await Services.PaysService.getTypeDemandess(
        country.id,
        abortController.signal
      );

      //   console.log("typeDemandes", typeDemandes);
      let filtre_demanded = typeDemandes.type_demandes.find(
        (demande) => demande.libelle === props.type_demande.libelle
      );
      //   console.log("filtre_demanded", filtre_demanded);

      setType_demande_id(filtre_demanded);
      useDemande.setType_demande_id(type_demande_id.id);

      setPays(country);
      setCountry(country.libelle);

      const { moyen_paiements } = await Services.MoyenPaiementService.getAlls(
        abortController.signal
      );
      //   console.log("moyen_paiements", moyen_paiements);

      setMoyens_payements(moyen_paiements);
    } catch (error) {
      if ("messages" in error) return;
      //toast
    }
  }, [abortController]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleStateReturnChange = () => {
    setIsPayment(false);
    setIsEditModalOpen(false);
  };

  const handleStatusPaiement = (statusPaiement) => {
    // return statusPaiement
    if (statusPaiement) {
      setStatusPaiements(statusPaiement);
    }
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    try {
      let send = {};

      if (props.questionnaireObject["contrat"] !== undefined) {
        send = {
          champs_questionnaire: props.questionnaireObject["contrat"],
        };
      } else {
        send = {
          champs_questionnaire: JSON.stringify(props.questionnaireObject),
        };
      }

      apiUrl
        ._put(`admin/demandes/${id}/champs-questionnaire`, send)
        .then((result) => {
          apiUrl
            ._post(`toosign/demande/${id}`)
            .then((resultData) => {
              setStatPaiment(true);
              setIsDisabled(false);
              setIsPayment(false);
              setTimeout(() => {
                handleStateReturnChange(false);
                setStatPaiment(null);
              }, 1500);
              toast.success("La demande a bien été complétée");
              navigate(`/document-processing/${id}/step`);
            })
            .catch((errorData) => {
              // toast.error(errorData?.message);

              setStatPaiment(true);
              setIsDisabled(false);
              setIsPayment(false);
              setTimeout(() => {
                handleStateReturnChange(false);
                setStatPaiment(null);
              }, 1500);
              navigate(`/document-processing/${id}/step`);
            });
        })
        .catch((error2) => {
          toast.error("Une erreur d'édition du questionnaire");
          setIsDisabled(false);
          setIsPayment(false);
        });

      // })
      // .catch((error5) => {
      //   toast.error("Une erreur d'édition du questionnaire");
      // });
    } catch (error) {
      setIsDisabled(false);
      setIsPayment(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handlePaymentMethodClick = async (e, moyen_paiement) => {
    e.preventDefault();

    setIsPayment(true);

    await apiUrl
      ._get(`admin/demandes/${id}`)
      .then((result) => {
        if (getStatus(result?.data?.demande)) {
          apiUrl
            ._get(`organisation/${currentUser.organisation_id}`)
            .then((result) => {
              setOrganization(result?.data?.organisation);
              setIsEditModalOpen(true);
            })
            .catch((error) => {
              toast.error(
                "Une erreur est survenue au niveau de la commande, veuillez réessayer svp."
              );
              setIsPayment(false);
            });
        } else {
          handlePaymentSubmit(e);
        }
        // setMapDataObject(JSON.parse(result?.data?.demande?.champs_demande));
      })
      .catch((error) => {
        setIsPayment(false);
        toast.error(
          "Une erreur s'est produite lors de la récupération de la demande."
        );
      });
  };

  return (
    <div
      className={`col-span-12 mt-8 ${props.step !== props.index ? "hidden" : ""
        }`}
    >
      <div className="intro-y box py-10 sm:py-20 mt-">
        <Components.Payment
          moyen_paiements={moyen_paiements}
          currency={props?.pays?.monnaie}
          handlePaymentClick={handlePaymentMethodClick}
          amount={props?.paymentAmount}
          isPayment={isPayment}
          handleBackClick={props.handleQuestionnaireBackClick ?? null}
        />
      </div>
      {isEditModalOpen ? (
        <Components.Modal
          handleModalClose={() => {
            setIsEditModalOpen(false);
            setIsPayment(false);
          }}
          handleModalValidate={() => setIsEditModalOpen(false)}
          title={"Paiement"}
        >
          <ModalPaiment
            dataPaiement={itemDemande}
            handleStateReturnChange={handleStateReturnChange}
            paymentAmount={props?.paymentAmount}
            statusPaiement={handleStatusPaiement}
            organisation={organization}
            handlePaymentSubmit={handlePaymentSubmit}
            isQuestionnaire={true}
            statePaiment={statePaiment}
            setStatPaiment={setStatPaiment}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            setIsEditModalOpen={setIsEditModalOpen}
            handleSubmits={props.handleSubmit}
          />
        </Components.Modal>
      ) : null}
    </div>
  );
}
