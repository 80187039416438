import React, { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import Flooz from "../assets/images/moovmoney.png";
import OM from "../assets/images/OM.svg";
import momo from "../assets/images/momo.svg";
import Wave from "../assets/images/Wave.svg";
import success from "../assets/images/success.svg";
import errorIcons from "../assets/images/error.svg";
import waitingIcon from "../assets/images/waiting.png";
import searchAnimate from "../assets/images/search.gif";
import { Services } from "../services";
import { handleGenerateDocument } from "../utils/utility";

import ApiService from "../api/services/api.service";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../utils/navigation";
const apiUrl = new ApiService();

// import second from "";

const ModalPaiment = (props) => {
  console.log("propspayment", props)
  const {
    dataPaiement,
    paymentAmount,
    organisation,
    handleSubmits,
    isQuestionnaire,
    handlePaymentSubmit,
    setIsEditModalOpen,
  } = props;

  console.log("props", dataPaiement)
  // console.log("ModalPaiment", dataPaiement, paymentAmount, organisation);
  const [otp, setOtp] = useState("");
  const [formData, setFormData] = useState({});
  const [moyen_paiements, setMoyen_paiements] = useState([]);
  const [checkPaiement, setCheckPaiement] = useState(false);
  const [paiement, setPaiement] = useState(false);
  const [generatePaiement, seteneratePaiement] = useState(false);
  const [paiementWave, setPaiementWave] = useState(false);
  const [statePaiement, setStatePaiement] = useState({});
  const [phone, setPhone] = useState("");
  const [frame, setFrame] = useState("");
  let navigate = useNavigate();
  const { id: urlId } = useParams(); // Récupère l'ID depuis l'URL


  const operateurs = [
    { name: "Orange Monney", value: "orange" },
    { name: "Mtn Monney", value: "mtn" },
    { name: "Moov Monney", value: "moov" },
    { name: "Wave", value: "wave" },
  ];

  /* Toast option */


  const getMoyenPaiement = async () => {
    const { moyen_paiements } = await Services.MoyenPaiementService.getAll(
      AbortController.signal
    );

    const moyen_paiementsCopy = moyen_paiements?.filter((moyen_paiement) =>
      moyen_paiement.libelle.includes("Mobile")
    );
    // console.log("moyen_paiementsCopy", moyen_paiementsCopy);
    // check/paiement/{demande_id}
    setMoyen_paiements(moyen_paiementsCopy);
    // return moyen_paiementsCopy[0].id;
  };
  // console.log("moyen_paiements", moyen_paiements[0]);
  const handleOperatorChange = (event) => {
    const { name, value } = event.target;
    // console.log("name, value ", name, value);
    //  setOperator(false);

    setFormData({ ...formData, [name]: value });

    if (otp) {
      setFormData({ ...formData, otp: otp });
    }
  };

  useEffect(() => {
    setFormData((values) => ({ ...values, otp }));
    setFormData((values) => ({ ...values, "numero-operateur": phone }));
    getMoyenPaiement();
  }, [otp, phone]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    // props.setIsDisabled(true);
    setFormData((values) => ({ ...values, otp }));
    let payment_datas;

    const data_wave = {
      onSuccessRedirectionUrl:
        "https://legafrik.s3.us-west-2.amazonaws.com/Design%20sans%20titre%20%281%29.jpg",
      onFailedRedirectionUrl:
        "https://legafrik.s3.us-west-2.amazonaws.com/Design%20sans%20titre%20%281%29.jpg",
      onCancelRedirectionUrl:
        "https://legafrik.s3.us-west-2.amazonaws.com/Design%20sans%20titre%20%281%29.jpg",
      onFinishRedirectionUrl:
        "https://legafrik.s3.us-west-2.amazonaws.com/Design%20sans%20titre%20%281%29.jpg",
    };

    payment_datas = {
      type_paiement: "hub2",
      currency: "XOF",
      payment_method: "mobile_money",
      country: "CI",
      provider: formData.operator,
      msisdn: formData["numero-operateur"],
      otp: formData.otp,
    };
    if (formData.operator && formData.operator === "wave") {
      payment_datas = { ...payment_datas, ...data_wave };
    }
    const dataPaiments = {
      type_paiement_id: 1,
      moyen_paiement_id: moyen_paiements[0].id,
      demande_id: `${dataPaiement.id}`,
      montant: "100",
      // dataPaiement.montant_total
      //   ? dataPaiement.montant_total
      //   : paymentAmount
      commission_fixe: 0,
      pourcentage_commission: Array.isArray(dataPaiement.organisation) && dataPaiement.organisation.length > 0
        ? (dataPaiement.organisation[0]?.valeur_commission ?? 0) // Utilise 0 si valeur_commission est undefined ou vide
        : (organisation?.valeur_commission ?? 0), // Utilise 0 si valeur_commission est undefined ou vide

      payment_data: JSON.stringify(payment_datas),
      // '{"type_paiement":"hub2","currency":"XOF","moyen_paiement_id":1,"payment_method":"mobile_money","provider":"orange","msisdn":"00000001","otp":"0001","description":"This is test payment","country":"CI"}',
    };
    // setFormData({ ...formData, otp: otp });

    await apiUrl
      ._post(`user/paiements`, JSON.stringify(dataPaiments))
      .then((result) => {
        // console.log("paiements", result);
        if (result.data.success === true) {
          setCheckPaiement(true);
          setPaiementWave(true);
          handleGenerateDocument(dataPaiement.id, apiUrl, setCheckPaiement);


        }
      })
      .catch((error2) => {
        toast.error(
          "Une erreur est survenue au niveau du paiement. Veuillez réessayer, svp"
        );
        props.setIsDisabled(false);
        setIsEditModalOpen(false);
      });
  };
  // console.log("checkPaiement", checkPaiement);

  const handleStatusPaiement = async (e) => {
    try {
      setPaiement(true);
      await apiUrl._get(`check/paiement/${dataPaiement.id}`).then((result) => {
        setStatePaiement(result.data);
        if (result.data.status.includes("termine")) {
          console.log("data", result.data)
          if (isQuestionnaire) {
            handlePaymentSubmit(e);
            setPaiement(true);
            console.log("urlID", urlId)
            handleGenerateDocument(dataPaiement.id, apiUrl, setPaiement);
            navigate(`/document-processing/${dataPaiement.id}/step`);

          } else {
            setPaiement(true);
            handleGenerateDocument(dataPaiement.id, apiUrl, setPaiement);
            navigate(`/document-processing/${dataPaiement.id}/step`);

          }
          setIsEditModalOpen(false);
          props.handleStateReturnChange(false);
          setPaiement(true);
          handleGenerateDocument(dataPaiement.id, apiUrl, setPaiement);
          navigate(`/document-processing/${dataPaiement.id}/step`);
        }
        if (result.data.status.includes("en-cours")) {
          if (result.data.next_action) {

            setFrame(result.data.next_action.data.url);
          } else {
            setTimeout(() => {
              setPaiement(false);
            }, 1000);
          }
        }
        if (result.data.status.includes("annule")) {
          setTimeout(() => {
            handleSubmits(e);
            navigate(DASHBOARD);
          }, 1500);
        }
      });
      setPaiement(false);
    } catch (error) {
      setPaiement(false);
      console.log("error", error);
    }
  };
  // console.log("formData", formData);

  return (
    <form onSubmit={handleSubmit} className="bg-gray-100">
      <div>
        {props.statePaiment ? (
          <Alert variant="success" className="text-center">
            Paiement effectué avec success
          </Alert>
        ) : null}
        {props.statePaiment === false ? (
          <Alert variant="danger" className="text-center">
            Désolé le paiement n'a pu être effectué
          </Alert>
        ) : null}
      </div>
      <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 p-3 m-2 text-md box">
        <div className="col-span-12">
          <div className="flex">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              N° de la demande:
            </label>
            <div className="mr-3 font-semibold">
              {dataPaiement?.numero_demande}
            </div>
          </div>
        </div>
        <div className="intro-y flex justify-between col-span-12 sm:col-span-12">
          <div className="flex">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              Client:
            </label>
            <div className=" font-semibold">{dataPaiement?.user?.username}</div>
          </div>
          <div className="flex">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              Demande:
            </label>
            <div className=" font-semibold">
              {dataPaiement?.type_demande?.libelle}
            </div>
          </div>
        </div>

        <div className="intro-y flex  justify-between col-span-12 sm:col-span-12">
          <div className="flex">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              Montant:
            </label>
            <div className=" font-semibold">
              {dataPaiement?.montant_total
                ? dataPaiement?.montant_total
                : paymentAmount
                  ? paymentAmount
                  : 0}
              FCFA
            </div>
          </div>
          <div className="flex">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              Entreprise:
            </label>
            <div className=" font-semibold">
              {dataPaiement?.champs_demande
                ? JSON.parse(dataPaiement?.champs_demande)?.denomination[0]
                : "N/A"}
            </div>
          </div>
        </div>

        <div className="intro-y flex justify-between col-span-12 sm:col-span-12">
          <div className="flex items-center gap-3">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              Operateur:
            </label>
            <div className=" font-semibold">
              {formData.operator ? (
                <div className="font-semibold text-gray-700 flex gap-3 items-center justify-between  w-full  box p-3">
                  {(() => {
                    switch (formData.operator) {
                      case "orange":
                        return (
                          <img
                            alt={OM}
                            className="w-8 "
                            style={{ borderRadius: 3 }}
                            src={OM}
                          />
                        );
                      case "mtn":
                        return (
                          <img
                            alt={momo}
                            className="w-8 "
                            style={{ borderRadius: 3 }}
                            src={momo}
                          />
                        );
                      case "moov":
                        return (
                          <img
                            alt={Flooz}
                            className="w-10 "
                            style={{ borderRadius: 3 }}
                            src={Flooz}
                          />
                        );
                      case "wave":
                        return (
                          <img
                            alt={Wave}
                            className="w-8 "
                            style={{ borderRadius: 3 }}
                            src={Wave}
                          />
                        );

                      default:
                        return;
                    }
                  })()}
                  {formData.operator.toUpperCase()}
                </div>
              ) : (
                "Non defini"
              )}
            </div>
          </div>
          <div className="flex items-center">
            <label className="text-sm font-semibold text-gray-700 mr-1">
              numéro de l'opération :
            </label>
            <div className=" font-semibold">{phone ? phone : "Non defini"}</div>
          </div>
        </div>
      </div>
      {!statePaiement.status ? (
        <div
          disabled
          className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 p-3 "
        >
          {operateurs.map((item, index) => (
            <div
              key={index}
              className="intro-y flex justify-between col-span-12 sm:col-span-6"
            >
              {/* <div className=""> */}
              <label
                className={`${!checkPaiement || !paiementWave
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
                  } font-semibold text-gray-700 flex items-center justify-between  w-full  box p-3`}
                htmlFor={item.name}
              >
                {(() => {
                  switch (item.name) {
                    case "Orange Monney":
                      return (
                        <img
                          alt={OM}
                          className="w-12 "
                          style={{ borderRadius: 3 }}
                          src={OM}
                        />
                      );
                    case "Mtn Monney":
                      return (
                        <img
                          alt={momo}
                          className="w-12 "
                          style={{ borderRadius: 3 }}
                          src={momo}
                        />
                      );
                    case "Moov Monney":
                      return (
                        <img
                          alt={Flooz}
                          className="w-12 "
                          style={{ borderRadius: 3 }}
                          src={Flooz}
                        />
                      );
                    case "Wave":
                      return (
                        <img
                          alt={Wave}
                          className="w-12 "
                          style={{ borderRadius: 3 }}
                          src={Wave}
                        />
                      );
                    default:
                      return (
                        <div className="text-theme-11 text-3xl">
                          Indisponible pour le moment
                        </div>
                      );
                  }
                })()}
                {item.name}
                <input
                  type="radio"
                  name="operator"
                  id={item.name}
                  value={item.value}
                  className="input border my-auto"
                  onClick={(e) => handleOperatorChange(e)}
                  disabled={checkPaiement || paiementWave}
                />
              </label>
              {/* </div> */}
            </div>
          ))}

          <div className="intro-y col-span-12 sm:col-span-12 my-4 ">
            {checkPaiement ? (
              <div className="mx-auto w-20">
                <img
                  alt="pending"
                  className="w-full"
                  style={{ borderRadius: 3 }}
                  src={searchAnimate}
                />
              </div>
            ) : (
              <>
                <label className="text-sm font-semibold text-gray-700 my-auto mb-2">
                  Veuillez saisir le numéro de l'opération
                </label>
                <PhoneInput
                  country={"ci"}
                  disabled={checkPaiement || paiementWave}
                  value={phone}
                  placeholder="numéro"
                  onChange={setPhone}
                  autoFormat={false}
                  inputStyle={{
                    padding: "21px 20px 21px 52px",
                    width: "100%",
                    boxShadow: "0px 3px 5px #00000007",
                    borderColor: "rgba(226, 232, 240, 1)",
                  }}
                />
              </>
            )}

            {/* <input
            type="text"
            name="numero-operateur"
            className="input w-full border flex-1"
            placeholder="numéro"
            onChange={handleOperatorChange}
          /> */}
          </div>
          {formData.operator && formData.operator === "orange" ? (
            <>
              <div className="intro-y  col-span-12 sm:col-span-12">
                Obtenez un code de confirmation en composant le
                <span className="font-semibold"> #144*82#. </span>
                Saisissez le code reçu dans le champ ci-dessous puis cliquez sur
                "Confirmer le dépôt"
              </div>

              <OtpInput
                containerStyle="input w-full intro-y justify-center col-span-12 sm:col-span-12"
                inputStyle={{
                  width: "10%",
                  height: 40,
                  borderRadius: 3,
                  border: "1px solid #cbd5e0",
                  fontSize: 25,
                  margin: "0 10px ",
                }}
                inputType="number"
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span> - </span>}
                renderInput={(props) => <input {...props} />}
              />
            </>
          ) : null}

          <div className="intro-y col-span-12 sm:col-span-12">
            <span className="mx-2">Statut du paiement</span>
            <span>En attente</span>

          </div>
        </div>
      ) : null}
      <div>
        {frame && (
          <iframe
            src={frame}
            width={660}
            height={500}
            loading="lazy"
            title="wave"
          ></iframe>
        )}
      </div>

      {/* <div className="intro-y flex justify-center my-20">
          <img
            alt={Wave}
            className="w-1/4 "
            style={{ borderRadius: 3 }}
            src={success}
          />
        </div> */}
      {statePaiement.status && statePaiement.status.includes("termine") ? (
        <div className="intro-y flex justify-center my-20">
          <img
            alt="sucessfully"
            className="w-1/4 "
            style={{ borderRadius: 3 }}
            src={success}
          />
        </div>
      ) : !formData.operator === "wave" &&
        statePaiement.status &&
        statePaiement.status.includes("en-cours") ? (
        <div className="intro-y flex justify-center my-20">
          <img
            alt="pending"
            className="w-1/4 "
            style={{ borderRadius: 3 }}
            src={waitingIcon}
          />
        </div>
      ) : statePaiement.status && statePaiement.status.includes("annule") ? (
        <div className="intro-y flex justify-center my-20">
          <img
            alt="denied"
            className="w-1/4 "
            style={{ borderRadius: 3 }}
            src={errorIcons}
          />
        </div>
      ) : null}
      <div className="intro-y col-span-12 flex items-center sm:justify-end mt-10">
        <button
          type="button"
          className="button w-24 justify-center block bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-300"
          onClick={() => {
            props.handleStateReturnChange(false); // Fermer le modal ou autre état
            window.location.reload(); // Actualiser la page
          }}
        >
          Fermer
        </button>

        {formData.operator && !checkPaiement && formData.operator !== "wave" ? (
          <button
            type="submit"
            // onClick={handleStatusPaiement}

            disabled={props.isDisabled}
            className="button  justify-center block bg-theme-1 text-white ml-2"
          >
            {props.isDisabled ? "paiement encours..." : "Confirmer le paiement"}
          </button>
        ) : null}

        {formData.operator && checkPaiement && formData.operator !== "wave" ? (
          <button
            type="button"
            onClick={handleStatusPaiement}

            className="button  justify-center block bg-theme-1 text-white ml-2"
          >
            {paiement
              ? "verification encours..."
              : "Verifié l'état du paiement"}
          </button>
        ) : null}

        {!paiementWave && formData.operator === "wave" ? (
          <button
            type="submit"
            // type="button"
            // type="submit"
            disabled={props.isDisabled}
            className="button  justify-center block bg-theme-1 text-white ml-2"
          >
            {props.isDisabled ? "paiement encours..." : "Confirmer le paiement"}
          </button>
        ) : null}
        {paiementWave && formData.operator === "wave" ? (
          <button
            onClick={() => { handleStatusPaiement(); seteneratePaiement(true) }}
            disabled={!props.isDisabled}
            className="button  justify-center block bg-theme-1 text-white ml-2"
          >
            {!generatePaiement
              ? "Generer le lien du paiement"
              : "Verifié l'état du paiement"}
          </button>
        ) : null}
        { }
        {/* {!paiementWave && formData.operator === "wave" ? (
          <button
            type="button"
            onClick={handleStatusPaiement}
            className="button  justify-center block bg-theme-1 text-white ml-2"
          >
            {paiement
              ? "verification encours..."
              : "Verifié l'état du paiement"}
          </button>
        ) : null} */}
      </div>
    </form>
  );
};

export default ModalPaiment;
