import { Auth } from './Auth';
import { Dom } from './Dom'
import { Date } from './Date';
import { Demande } from './Demande';
import { String } from './String';

export const Utils = {
    Auth,
    Dom,
    Date,
    Demande,
    String,
    localStorage,
}