// import { useState } from "react";
// import Alert from "react-bootstrap/Alert";
// import * as Icons from "react-feather";

// export function SigleStepOng(props) {

//   const formContainer = "sigle-form-container";

//   const [sigle, setSigle] = useState(props?.sigle);
//   const [sigleType, setSigleType] = useState(
//     props?.sigle?.sigle === undefined
//       ? "non"
//       : props?.sigle?.sigle !== ""
//         ? "oui"
//         : "non"
//   );

//   const handleSigleChange = (value) => {
//     if (value === "non") {
//       const formContainerElem = window.document.querySelector(
//         `#${formContainer}`
//       );
//       Array.from(formContainerElem.querySelectorAll(".field")).forEach(
//         (field) => (field.value = "")
//       );

//       setSigle("");
//     }

//     setSigleType(value);
//   };
//   const handleStepSubmit = (e) => {
//     e.preventDefault();

//     /*           if(sigleType==='non' || sigle  ){
//             const sigleObject = {sigle,"sigle-obligatoire": ""}
//             props.handleNextClick(e, 'sigle', JSON.stringify(sigleObject));

//            }
//         else {
//             return alert("Vous devez remplir le formulaire !");
//         } */

//     if (sigleType === "oui" || sigleType === "non") {
//       if (
//         sigleType === "oui" &&
//         (sigle.sigle === undefined || sigle.sigle === "")
//       ) {
//         return alert("Vous devez remplir le formulaire !");
//       }
//       if (typeof sigle !== 'object' || sigle === null) {
//         sigle = {}; // Cela échouera si sigle est const
//       }

//       if (sigle.sigle === undefined) {
//         sigle.sigle = ""; // Modifie la propriété sans réassigner l'objet
//       }

//       sigle["sigle-obligatoire"] = false;
//       props.handleNextClick(e, "sigle", JSON.stringify(sigle));
//     } else {
//       return alert("Vous devez remplir le formulaire !");
//     }
//   };

//   return (
//     <div
//       className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
//         ${props.step !== props.index ? "hidden" : ""}`}
//       id={`${formContainer}`}
//     >
//       <h4 className="intro-y col-span-12 text-lg leading-none">
//         Le nom de votre organisation est : <strong>{props.nomSociete}</strong>
//       </h4>
//       <div className="intro-y col-span-12 ">
//         <div className="intro-y col-span-12  ">
//           <strong>La société a t’elle un sigle (Ex : SODECI) ?</strong>
//         </div>

//         <div className="intro-y col-span-12">
//           <Alert variant="danger">
//             <Alert.Heading>
//               <Icons.Info className="w-4 h-4 " />
//             </Alert.Heading>
//             <Alert.Link href="#">Le sigle</Alert.Link> d’une organisation
//             correspond à
//             <Alert.Link href="#">
//               {" "}
//               un diminutif du nom de l'organisation
//             </Alert.Link>
//             . Le sigle peut être utile lorsque le nom de l'organisation est
//             long.
//           </Alert>
//         </div>
//         <div className="flex flex-col sm:flex-row mt-4 mb-2">
//           <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
//             <input
//               type="radio"
//               className="input border mr-2"
//               id={`horizontal-non-0`}
//               name={`sigle-0`}
//               value="non"
//               checked={sigleType === "non"}
//               onChange={(e) => handleSigleChange("non")}
//             />
//             <label
//               className="cursor-pointer select-none"
//               htmlFor={`horizontal-non-0`}
//             >
//               Non
//             </label>
//           </div>
//           <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
//             <input
//               type="radio"
//               className="input border mr-2"
//               id={`horizontal-radio-oui-0`}
//               name={`sigle-0`}
//               value="non"
//               checked={sigleType === "oui"}
//               onChange={(e) => handleSigleChange("oui")}
//             />
//             <label
//               className="cursor-pointer select-none"
//               htmlFor={`horizontal-radio-oui-0`}
//             >
//               Oui
//             </label>
//           </div>
//         </div>
//         <input
//           type="text"
//           value={sigle?.sigle}
//           className={`input w-full border flex-1 field 
//                 ${sigleType === "non" ? "hidden" : " "}`}
//           placeholder="Votre réponse"
//           disabled={(!sigle && sigleType === "") || sigleType === "non"}
//           onChange={(e) =>
//             setSigle({
//               ...sigle,
//               sigle: e.target.value,
//             })
//           }
//         />
//       </div>

//       <div
//         className="intro-y col-span-12 flex items-center justify-center
//                 sm:justify-end mt-10"
//       >
//         {/* <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
//                 dark:bg-dark-1 dark:text-gray-300" onClick={props.handleBackClick ?? null}>
//                     Revenir
//                 </button> */}
//         <button
//           className="button w-24 justify-center block bg-theme-1 
//                 text-white ml-2"
//           onClick={handleStepSubmit}
//         >
//           Suivant
//         </button>
//       </div>
//     </div>
//   );
// }

import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function SigleStepOng(props) {
  const formContainer = "sigle-form-container";

  const [sigle, setSigle] = useState(props?.sigle || { sigle: "" });
  const [sigleType, setSigleType] = useState(
    props?.sigle?.sigle === undefined
      ? "non"
      : props?.sigle?.sigle !== ""
        ? "oui"
        : "non"
  );

  const handleSigleChange = (value) => {
    if (value === "non") {
      const formContainerElem = window.document.querySelector(
        `#${formContainer}`
      );
      Array.from(formContainerElem.querySelectorAll(".field")).forEach(
        (field) => (field.value = "")
      );

      setSigle({ sigle: "" }); // Assurez-vous que c'est un objet
    }

    setSigleType(value);
  };

  const handleStepSubmit = (e) => {
    e.preventDefault();

    /* 
      Vérifie si le type de sigle est "non" ou "oui".
      Si "oui", vérifie que le sigle est rempli.
    */
    if (sigleType === "oui" || sigleType === "non") {
      if (sigleType === "oui" && (sigle.sigle === undefined || sigle.sigle === "")) {
        return alert("Vous devez remplir le formulaire !");
      }

      // Créer l'objet sigle pour la soumission
      const sigleObject = {
        sigle: sigle.sigle || "",
        "sigle-obligatoire": false
      };

      props.handleNextClick(e, "sigle", JSON.stringify(sigleObject));
    } else {
      return alert("Vous devez remplir le formulaire !");
    }
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
      id={`${formContainer}`}
    >
      <h4 className="intro-y col-span-12 text-lg leading-none">
        Le nom de votre organisation est : <strong>{props.nomSociete}</strong>
      </h4>
      <div className="intro-y col-span-12 ">
        <div className="intro-y col-span-12  ">
          <strong>La société a-t-elle un sigle (Ex : SODECI) ?</strong>
        </div>

        <div className="intro-y col-span-12">
          <Alert variant="danger">
            <Alert.Heading>
              <Icons.Info className="w-4 h-4 " />
            </Alert.Heading>
            <Alert.Link href="#">Le sigle</Alert.Link> d’une organisation
            correspond à
            <Alert.Link href="#">
              {" "}
              un diminutif du nom de l'organisation
            </Alert.Link>
            . Le sigle peut être utile lorsque le nom de l'organisation est
            long.
          </Alert>
        </div>
        <div className="flex flex-col sm:flex-row mt-4 mb-2">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0`}
              name={`sigle-0`}
              value="non"
              checked={sigleType === "non"}
              onChange={() => handleSigleChange("non")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-non-0`}
            >
              Non
            </label>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0`}
              name={`sigle-0`}
              value="oui" // Changer ici de "non" à "oui"
              checked={sigleType === "oui"}
              onChange={() => handleSigleChange("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0`}
            >
              Oui
            </label>
          </div>
        </div>
        <input
          type="text"
          value={sigle?.sigle || ""}
          className={`input w-full border flex-1 field 
                ${sigleType === "non" ? "hidden" : " "}`}
          placeholder="Votre réponse"
          disabled={(!sigle && sigleType === "") || sigleType === "non"}
          onChange={(e) =>
            setSigle((prevSigle) => ({
              ...prevSigle,
              sigle: e.target.value,
            }))
          }
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        {/* 
          Bouton pour passer à l'étape suivante.
        */}
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
