import * as constants from "./constants";
export const  demandsListRequest =(payload)=> {
    return { type: constants.DEMANDS_LIST_REQUESTING,payload}
}


export const demandsListSuccess=(payload)=>{
    return { type:constants.DEMANDS_LIST_SUCCESS, payload }
}


export const demandsListFailed=(error)=>{
    return { type:constants.DEMANDS_LIST_FAILED, error }
}

export const  demandsPaymentListRequest =(payload)=> {
    return { type: constants.DEMANDS_PAYMENTS_LIST_REQUESTING,payload}
}

export const demandsPaymentListSuccess=(payload)=>{
    return { type:constants.DEMANDS_PAYMENTS_LIST_SUCCESS, payload }
}

export const demandsPaymentListFailed=(error)=>{
    return { type:constants.DEMANDS_PAYMENTS_LIST_FAILED, error }
}

export const  demandsDetailRequest =(payload)=> {
    return { type: constants.DEMANDS_DETAIL_REQUESTING, payload}
}

export const demandsDetailSuccess=(payload)=>{
    return { type:constants.DEMANDS_DETAIL_SUCCESS, payload }
}

export const demandsDetailFailed=(error)=>{
    return { type:constants.DEMANDS_DETAIL_FAILED, error }
}
export const  demandsDeletePieceRequest =(payload)=> {
    return { type: constants.DEMANDS_DELETE_PIECE_REQUESTING, payload}
}
export const demandsDeletePieceSuccess=(payload)=>{
    return { type:constants.DEMANDS_DELETE_PIECE_SUCCESS, payload }
}
export const demandsDeletePieceFailed=(error)=>{
    return { type:constants.DEMANDS_DELETE_PIECE_FAILED, error }
}

export const  demandsAddDocumentRequest =(payload)=> {
    return { type: constants.DEMANDS_ADD_DOCUMENT_REQUESTING, payload}
}
export const demandsAddDocumentSuccess=(payload)=>{
    return { type:constants.DEMANDS_ADD_DOCUMENT_SUCCESS, payload }
}
export const demandsAddDocumentFailed=(error)=>{
    return { type:constants.DEMANDS_ADD_DOCUMENT_FAILED, error }
}
export const  demandsEditDocumentRequest =(payload)=> {
    return { type: constants.DEMANDS_EDIT_DOCUMENT_REQUESTING, payload}
}
export const demandsEditDocumentSuccess=(payload)=>{
    return { type:constants.DEMANDS_EDIT_DOCUMENT_SUCCESS, payload }
}
export const demandsEditDocumentFailed=(error)=>{
    return { type:constants.DEMANDS_EDIT_DOCUMENT_FAILED, error }
}

export const  demandsDeleteDocumentRequest =(payload)=> {
    return { type: constants.DEMANDS_DELETE_DOCUMENT_REQUESTING, payload}
}
export const demandsDeleteDocumentSuccess=(payload)=>{
    return { type:constants.DEMANDS_DELETE_DOCUMENT_SUCCESS, payload }
}
export const demandsDeleteDocumentFailed=(error)=>{
    return { type:constants.DEMANDS_DELETE_DOCUMENT_FAILED, error }
}
export const  demandsPieceListRequest =(payload)=> {
    return { type: constants.DEMANDS_PIECES_LIST_REQUESTING,payload}
}
export const demandsPieceListSuccess=(payload)=>{
    return { type:constants.DEMANDS_PIECES_LIST_SUCCESS, payload }
}
export const demandsPieceListFailed=(error)=>{
    return { type:constants.DEMANDS_PIECES_LIST_FAILED, error }
}

export const  demandsDocumentsListRequest =(payload)=> {
    return { type: constants.DEMANDS_DOCUMENTS_LIST_REQUESTING,payload}
}
export const demandsDocumentsListSuccess=(payload)=>{
    return { type:constants.DEMANDS_DOCUMENTS_LIST_SUCCESS, payload }
}
export const demandsDocumentsListFailed=(error)=>{
    return { type:constants.DEMANDS_DOCUMENTS_LIST_FAILED, error }
}

export const  demandsObservationsListRequest =(payload)=> {
    return { type: constants.DEMANDS_OBSERVATIONS_LIST_REQUESTING,payload}
}
export const demandsObservationsListSuccess=(payload)=>{
    return { type:constants.DEMANDS_OBSERVATIONS_LIST_SUCCESS, payload }
}
export const demandsObservationsListFailed=(error)=>{
    return { type:constants.DEMANDS_OBSERVATIONS_LIST_FAILED, error }
}


export const  demandsStatusEtatTraitementListRequest =(payload)=> {
    return { type: constants.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_REQUESTING, payload}
}
export const demandsStatusEtatTraitementListSuccess=(payload)=>{
    return { type:constants.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_SUCCESS, payload }
}
export const demandsStatusEtatTraitementListFailed=(error)=>{
    return { type:constants.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_FAILED, error }
}

export const demandsStepCepiciData=(payload)=>{
    return { type:constants.DEMANDS_STEP_CEPICI_DATA, payload }
}

export const demandsStepObtentionDfeData=(payload)=>{
    return { type:constants.DEMANDS_STEP_OBTENTION_DFE_DATA, payload }
}

export const  demandsDocumentsComplementaireRequest =(payload)=> {
    return { type: constants.DEMANDS_DOCUMENT_COMPLEMENTAIRE_REQUESTING, payload}
}
export const demandsDocumentsComplementaireSuccess=(payload)=>{
    return { type:constants.DEMANDS_DOCUMENT_COMPLEMENTAIRE_SUCCESS, payload }
}
export const demandsDocumentsComplementaireFailed=(error)=>{
    return { type:constants.DEMANDS_DOCUMENT_COMPLEMENTAIRE_FAILED, error }
}

//Demande par pays
export const  demandsPaysListRequest =(payload)=> {
    return { type: constants.DEMANDS_PAYS_LIST_REQUESTING,payload}
}
export const demandsPaysListSuccess=(payload)=>{
    return { type:constants.DEMANDS_PAYS_LIST_SUCCESS, payload }
}
export const demandsPaysListFailed=(error)=>{
    return { type:constants.DEMANDS_PAYS_LIST_FAILED, error }
}


export const  demandsEditQuestionnaireRequest =(payload,history)=> {
    return { type: constants.DEMANDS_EDIT_QUESTIONNAIRE_REQUESTING, payload,history}
}
export const demandsEditQuestionnaireSuccess=(payload)=>{
    return { type:constants.DEMANDS_EDIT_QUESTIONNAIRE_SUCCESS, payload }
}
export const demandsEditQuestionnaireFailed=(error)=>{
    return { type:constants.DEMANDS_EDIT_QUESTIONNAIRE_FAILED, error }
}

export const  demandsPaymentsResetList =()=> {
    return { type: constants.DEMANDS_PAYMENTS_RESET_LIST}
}

//Demandes type list
export const  demandsTypeListRequest =(payload)=> {
    return { type: constants.DEMANDS_TYPE_LIST_REQUESTING,payload}
}
export const demandsTypeListSuccess=(payload)=>{
    return { type:constants.DEMANDS_TYPE_LIST_SUCCESS, payload }
}
export const demandsTypeListFailed=(error)=>{
    return { type:constants.DEMANDS_TYPE_LIST_FAILED, error }
}

//Create lead
export const  createDemandsRequest =(payload,history)=> {
    return { type: constants.CREATE_DEMANDS_REQUESTING,payload,history}
}

export const createDemandsSuccess=(payload)=>{
    return { type:constants.CREATE_DEMANDS_SUCCESS, payload }
}

export const createDemandsFailed=(error)=>{
    return { type:constants.CREATE_DEMANDS_FAILED, error }
}


//Create on hubspot Leads
export const  createHubspotLeadsRequest =(payload)=> {
    return { type: constants.CREATE_HUBSPOT_LEADS_REQUESTING,payload}
}

export const createHubspotLeadsSuccess=(payload)=>{
    return { type:constants.CREATE_HUBSPOT_LEADS_SUCCESS, payload }
}

export const createHubspotLeadsFailed=(error)=>{
    return { type:constants.CREATE_HUBSPOT_LEADS_FAILED, error }
}

//Demands payment
export const  demandsPaymentRequest =(payload)=> {
    return { type: constants.DEMANDS_PAYMENT_REQUESTING,payload}
}

export const demandsPaymentSuccess=(payload)=>{
    return { type:constants.DEMANDS_PAYMENT_SUCCESS, payload }
}

export const demandsPaymentFailed=(error)=>{
    return { type:constants.DEMANDS_PAYMENT_FAILED, error }
}



export const  verificationUserRequest =(payload,create_payload,history)=> {
    return { type: constants.VERIFICATION_USER_REQUESTING,payload,create_payload,history}
}

export const verificationUserSuccess=(payload)=>{
    return { type:constants.VERIFICATION_USER_SUCCESS, payload }
}

export const verificationUserFailed=(error)=>{
    return { type:constants.VERIFICATION_USER_FAILED, error }
}